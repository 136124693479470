@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.button {
  @include flex();
  @include container(0.5rem);
  width: fit-content;
  border: 0.0625rem solid $blue;
  color: $blue;

  &.blue,
  &.blueonly {
    color: $white;
    background-color: $blue;
  }

  &.icononly {
    border: 0.0625rem solid transparent;
    background-color: $lightgrey;
    color: $black;
  }

  &.green {
    border: 0.0625rem solid $green;
    color: $white;
    background-color: $green;
  }

  &.red {
    border: 0.0625rem solid $red;
    color: $white;
    background-color: $red;
  }

  &.dropdown {
    color: $black;
    padding: 1rem 1rem 1rem 0.6rem;
    width: 100%;
    border: none;
    text-align: left;
    @include grid(0.8, 1fr, 2rem 1fr, center);

    span {
      white-space: nowrap;
    }

    &:hover {
      background-color: $lightgrey;
    }
  }

  &.white {
    color: $black;
    padding: 1rem 1rem 1rem 0.6rem;
    width: 100%;
    border: none;
    text-align: left;
    @include grid(0.2, 1fr, 2rem 1fr, center);

    span {
      white-space: nowrap;
    }
  }

  &.create {
    @include flex(column, center, center);
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 2;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: $blue;
    box-shadow: 0 0.0625rem 0.625rem rgba(31, 31, 31, 0.3);
    padding: 0;
    transition: 0.15s ease-in;

    img {
      width: 2rem;
    }

    span {
      display: none;
    }
  }

  &.greenDisabled {
    opacity: 0.4;
    border: 0.0625rem solid $green;
    color: $white;
    background-color: $green;
    cursor: not-allowed;
  }

  &.blueDisabled {
    opacity: 0.4;
    border: 0.0625rem solid $blue;
    color: $white;
    background-color: $blue;
    cursor: not-allowed;
  }

  &.borderless {
    color: $blue;
    border: none;
  }

  &.textonly {
    background-color: transparent;
  }

  &.textonly span,
  &.blueonly span,
  &.green span,
  &.greenDisabled span,
  &.red span,
  &.icononly span,
  &.dropdown span {
    margin-left: 0;
  }

  span {
    margin-left: 0.5rem;
  }
}
