@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import '~react-image-gallery/styles/scss/image-gallery.scss';

$black: #070417;
$grey: #788997;
$mediumgrey: #e5e5e5;
$lightgrey: #f3f5f8;
$white: #fff;
$blue: #5776f6;
$lightblue: #cddff1;
$red: #cf234e;
$mediumred: #ffbaba;
$lightred: #ffe5e5;
$green: #01b68c;

$radius: 0.5rem;

$inter: 'Inter',
sans-serif;
$rubik: 'Rubik',
sans-serif;

$shadow: 0 0.25rem 1.5rem rgba(185, 188, 197, 0.2);